import axios from 'axios';
import { setAxiosInterceptors, accessToken, refreshToken } from '@lendica/auth';
import {
    partnerDetails as partnerDetailsAPI,
    promoAds,
    promoVisitor,
    PromoVisitorResponse,
    PromoAd,
} from './IBranch/api/index';
import {
    initAnalytics,
    lendicaInitEvent,
    // convertToMarkdown,
    // screenshot,
    // uploadFile,
    // capture,
    // getIpAddress,
} from './analytics';
import { env, Env, backendUrl } from './constants';

let isAnalyticsInitialized = false;

export interface Credentials {
    partner_name: string;
    partner_company_uuid: number;
}

export interface Config {
    env: Env;
    fullscreen: boolean;
    buttonPositionPercentage: number;
    showSideButton?: boolean;
    partner?: {
        base_url: string;
        has_drawdown: boolean;
        has_fundnow: boolean;
        has_paylater: boolean;
        has_ipaylater: boolean;
        id: string;
        name: string;
    };
    nextStep: 'waitlist' | 'wallet';
    visitor: PromoVisitorResponse;
    ads: PromoAd[];
}

async function auth(credentials: Credentials): Promise<{
    refresh: string;
    access: string;
    status: number;
}> {
    let res = (await axios.post('/auth/partner', credentials)).data;
    return res;
}

async function companyInit(credentials: Credentials) {
    let res = (await axios.post('/company/init', credentials)).data;
    return res;
}

export let isInitialized = false;
export let config!: Config;

const visitorIdKey = 'ica_vik';

async function updateVisitor(partner_name?: string) {
    const visitor = await promoVisitor(window.localStorage.getItem(visitorIdKey), partner_name);
    window.localStorage.setItem(visitorIdKey, visitor.id);
    return visitor;
}

// function waitForRender(callback: () => void) {
//     const observer = new MutationObserver((mutations, obs) => {
//         clearTimeout(timeout);
//         timeout = setTimeout(() => {
//             obs.disconnect();
//             callback();
//         }, 5000);
//     });
//     let timeout = setTimeout(callback, 5000);
//     observer.observe(document.body, {
//         childList: true,
//         subtree: true,
//     });
// }

export async function init(
    initCredentials: Credentials | undefined,
    initConfig: Partial<Config> = {}
) {
    if (!isAnalyticsInitialized) {
        initAnalytics();
        isAnalyticsInitialized = true;
    }

    // TODO: should we send init event after updateVisitor,and send visitor id?
    lendicaInitEvent(initCredentials);

    isInitialized = false;

    axios.defaults.baseURL = backendUrl;

    await new Promise(res => {
        if (document.readyState === 'complete') {
            res(null);
            return;
        }
        window.addEventListener('load', () => {
            res(null);
        });
    });

    return new Promise(async (resolve, reject) => {
        if (initCredentials) {
            try {
                const companyInitResponse = await companyInit(initCredentials);
                const { partner_name, partner_company_uuid } = companyInitResponse.company;
                if (!partner_name || !partner_company_uuid) {
                    throw 'Unable to authorize';
                }
                const [authResponse, partnerDetails] = await Promise.all([
                    auth({ partner_name, partner_company_uuid }),
                    partnerDetailsAPI(partner_name),
                ]);
                accessToken.set(authResponse.access);
                refreshToken.set(authResponse.refresh);

                setAxiosInterceptors(backendUrl, axios, () => {});
                isInitialized = true;

                // // capture page
                // waitForRender(async () => {
                //     try {
                //         let screen, screenUrl, href, md, mdUrl;
                //         href = document.location.href;

                //         const hrefFilter = ['localhost', 'dev', 'sandbox', 'test', 'staging'];
                //         if (!!href && hrefFilter.some(f => href.toLowerCase().includes(f))) {
                //             return;
                //         }
                //         const partnerFilter = ['dev', 'treez', 'flourish', 'canix', 'apex'];
                //         if (
                //             !!partner_name &&
                //             partnerFilter.some(f => partner_name.toLowerCase().includes(f))
                //         ) {
                //             return;
                //         }

                //         screen = await screenshot();
                //         if (screen) {
                //             screenUrl = await uploadFile(
                //                 screen,
                //                 partner_name,
                //                 partner_company_uuid
                //             );
                //         }
                //         md = await convertToMarkdown();
                //         if (md) {
                //             mdUrl = await uploadFile(md, partner_name, partner_company_uuid);
                //         }

                //         const ip = await getIpAddress();

                //         const page = {
                //             title: document.title,
                //             url: href,
                //             html_content: mdUrl,
                //             partner_name,
                //             partner_company_uuid,
                //             company_id: companyInitResponse?.company?.id,
                //             company_name: companyInitResponse?.company?.company_name,
                //             screen: screenUrl,
                //             env: env,
                //             ip: ip,
                //         };

                //         const filteredPage = Object.fromEntries(
                //             Object.entries(page).filter(([_, v]) => v != null) // Removes null and undefined
                //         );
                //         capture(filteredPage);
                //     } catch (e) {
                //         // ignore
                //     }
                // });

                const visitor = await updateVisitor(partner_name);

                config = {
                    fullscreen: false,
                    buttonPositionPercentage: 50,
                    showSideButton: true,
                    env: env,
                    visitor,
                    ads: await promoAds(visitor.id, partner_name),
                    nextStep: 'wallet',
                    partner: partnerDetails.partner,
                    ...initConfig,
                };

                resolve({
                    status: 'success',
                    message: 'Successfully initialized with credentials.',
                });
            } catch (e) {
                console.log(e);
                reject({
                    status: 'error',
                    message: e.message || 'Unable to authorize.',
                });
            }
        } else {
            const visitor = await updateVisitor();
            config = {
                fullscreen: false,
                buttonPositionPercentage: 50,
                showSideButton: true,
                env: env,
                visitor,
                ads: await promoAds(visitor.id),
                nextStep: 'waitlist',
                ...initConfig,
            };
            resolve({
                status: 'waitlist',
                message: 'No credentials provided',
            });
        }
    });
}
