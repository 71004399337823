declare global {
    interface Window {
        dataLayer: any[];
        icaGtag: (...args: any[]) => void;
        lendicaConfig: any;
    }
}

const POD_MEASUREMENT_ID = 'G-DRKXGBK0D0';
const WARMLY_CLIENT_ID = 'b058eefee8cd0458a06048534d539f28';

export function initAnalytics() {
    appendGTagToDocument();
    initGTag();
    // appendReb2BToDocument();
    // appendWarmly();
}

// function appendReb2BToDocument() {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = 'https://s3-us-west-2.amazonaws.com/b2bjsstore/b/LNKLDHMRQ4OJ.js.gz';

//     // Append the script to the document head or body
//     document.body.appendChild(script);

//     script.onload = () => {
//         window.reb2b = window.reb2b || [];
//         if (window.reb2b.invoked) return;
//         window.reb2b.invoked = true;
//         window.reb2b.methods = ['identify', 'collect'];
//         window.reb2b.factory = function (method) {
//             return function () {
//                 var args = Array.prototype.slice.call(arguments);
//                 args.unshift(method);
//                 window.reb2b.push(args);
//                 return window.reb2b;
//             };
//         };
//         for (var i = 0; i < window.reb2b.methods.length; i++) {
//             var key = window.reb2b.methods[i];
//             window.reb2b[key] = window.reb2b.factory(key);
//         }
//         window.reb2b.SNIPPET_VERSION = '1.0.1';
//     };
// }

// function appendWarmly() {
//     if (document.getElementById('warmly-script-loader')) {
//         return;
//     }

//     const script = document.createElement('script');
//     script.id = 'warmly-script-loader';
//     script.src = `https://opps-widget.getwarmly.com/warmly.js?clientId=${WARMLY_CLIENT_ID}`;
//     script.defer = true;

//     document.body.appendChild(script);
// }

function appendGTagToDocument() {
    const script = document.createElement('script');

    script.src = `https://www.googletagmanager.com/gtag/js?id=${POD_MEASUREMENT_ID}`;
    script.async = true;

    document.body.appendChild(script);
}

function initGTag() {
    window.dataLayer = window.dataLayer || [];

    // window.icaGtag = (...args: any[]) => {
    //     // @ts-ignore
    //     window.dataLayer?.push(args);
    // }

    window.gtag = function () {
        // @ts-ignore
        dataLayer.push(arguments);
    };

    gtag('js', new Date());
    gtag('config', POD_MEASUREMENT_ID, { transport_url: 'https://gtm-pod.ue.r.appspot.com' });
}

export function lendicaInitEvent({ partner_company_uuid, partner_name, company_name }: any = {}) {
    window.lendicaConfig = {
        partner_company_uuid,
        partner_name,
        company_name,
    };

    if (partner_company_uuid) {
        gtag('event', 'lendica_init', {
            send_to: POD_MEASUREMENT_ID,
            partner_name: partner_name || location.hostname,
            partner_company_uuid: partner_company_uuid,
            company_name: company_name || '',
            ...(window.localStorage.getItem('ica_vik') && {
                visitor_id: window.localStorage.getItem('ica_vik'),
            }),
        });
    } else {
        gtag('event', 'lendica_unauthorized_init', {
            send_to: POD_MEASUREMENT_ID,
            partner_name: location.hostname,
            ...(window.localStorage.getItem('ica_vik') && {
                visitor_id: window.localStorage.getItem('ica_vik'),
            }),
        });
    }
}

export function payLaterRenderEvent() {
    gtag('event', 'paylater_render', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: location.hostname,
    });
}

export function payLaterUpdateEvent({
    total,
    partner_invoice_uuid,
    partner_company_uuid,
    partner_name,
}: any = {}) {
    gtag('event', 'paylater_update', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: partner_name || location.hostname,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
        invoice_amount: total,
    });
}

export function checkoutBtnClickEvent({
    type,
    total,
    partner_invoice_uuid,
    partner_company_uuid,
    partner_name,
}: any = {}) {
    gtag('event', 'checkout_click', {
        send_to: POD_MEASUREMENT_ID,
        type: type,
        partner_name: partner_name || location.hostname,
        invoice_amount: total,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
    });
}

export function learnMoreEvent({ partner_name }: any = {}) {
    gtag('event', 'learn_more', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: partner_name || location.hostname,
    });
}

export function attachIBranchEventListeners() {
    const sidebarButtonNode = document
        ?.querySelector('#ibranch-root div')
        ?.shadowRoot?.querySelector('#btn-sidebar-lendica');

    if (sidebarButtonNode) {
        sidebarButtonNode?.addEventListener('mouseenter', () => {
            iBranchAnalyticsEvents('entry_hover');
        });

        sidebarButtonNode?.addEventListener('click', () => {
            iBranchAnalyticsEvents('open');
        });
    } else {
        // If the button is not yet present, retry after a short delay
        setTimeout(attachIBranchEventListeners, 1000);
    }
}

export function iBranchAnalyticsEvents(eventName: string, rest?: any) {
    gtag('event', `ibranch_${eventName}`, {
        send_to: POD_MEASUREMENT_ID,
        ...window.lendicaConfig,
        ...(window.localStorage.getItem('ica_vik') && {
            visitor_id: window.localStorage.getItem('ica_vik'),
        }),
        ...rest,
    });
}

export function adsAnalyticsEvent(eventName: string, rest: any) {
    gtag('event', `ad_${eventName}`, {
        send_to: POD_MEASUREMENT_ID,
        ...window.lendicaConfig,
        ...(window.localStorage.getItem('ica_vik') && {
            visitor_id: window.localStorage.getItem('ica_vik'),
        }),
        ...rest,
    });
}

// export interface PartnerPage {
//     title: string;
//     url: string;
//     html_content?: string;
//     partner_name?: string;
//     partner_company_uuid?: string;
//     company_name?: string;
//     company_id?: string;
//     screen?: string;
//     env?: string;
//     ip?: string;
// }

// export const convertToMarkdown = async () => {
//     try {
//         // Load turndown
//         if (!window.TurndownService) {
//             await new Promise(resolve => {
//                 const script = document.createElement('script');
//                 script.src = 'https://unpkg.com/turndown/dist/turndown.js';
//                 script.onload = resolve;
//                 document.body.appendChild(script);
//             });
//         }

//         // Capture markdown
//         const turndownService = new TurndownService();
//         const markdown = turndownService.turndown(document?.body?.innerHTML);

//         return markdown;
//     } catch (e) {
//         // ignore
//         return '';
//     }
// };

// export const capture = async (page: PartnerPage) => {
//     try {
//         const response = await fetch('https://capture-lendica.vercel.app/api/capture', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(page),
//         });
//         const data = await response.json();
//         if (!data.success) throw new Error('Partner page data capture failed');
//         return data;
//     } catch (e) {
//         // ignore
//     }
// };

/**
 * Captures a screenshot, compresses it, and converts it to a File.
 * @param {number} compressionQuality - Quality (0.1 - 1.0) for JPEG compression.
 * @param {number} maxWidth - Maximum width for resizing.
 * @returns {Promise<File>} - The compressed screenshot as a File.
 */
// export const screenshot = async (
//     compressionQuality: number = 0.8,
//     maxWidth: number = 1200
// ): Promise<File | undefined> => {
//     try {
//         // Ensure html2canvas is loaded
//         if (!window.html2canvas) {
//             await new Promise(resolve => {
//                 const script = document.createElement('script');
//                 script.src =
//                     'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js';
//                 script.onload = resolve;
//                 document.body.appendChild(script);
//             });
//         }

//         // Capture screenshot
//         const canvas = await html2canvas(document.body);

//         // Resize image if larger than maxWidth
//         const scale = Math.min(1, maxWidth / canvas.width);
//         const newWidth = canvas.width * scale;
//         const newHeight = canvas.height * scale;

//         // Create an offscreen canvas for resizing
//         const offscreenCanvas = document.createElement('canvas');
//         offscreenCanvas.width = newWidth;
//         offscreenCanvas.height = newHeight;
//         const ctx = offscreenCanvas.getContext('2d');
//         ctx.drawImage(canvas, 0, 0, newWidth, newHeight);

//         // Convert to Blob
//         return new Promise(resolve => {
//             offscreenCanvas.toBlob(
//                 blob => {
//                     if (!blob) {
//                         console.error('Failed to create Blob from canvas');
//                         return;
//                     }
//                     resolve(new File([blob], 'screenshot.jpg', { type: 'image/jpeg' }));
//                 },
//                 'image/jpeg',
//                 compressionQuality
//             );
//         });
//     } catch (e) {
//         // ignore
//     }
// };

/**
 * Uploads the screenshot to `/api/images` and returns the public URL.
 * @param {File} file - The screenshot file.
 * @returns {Promise<string>} - The public URL of the uploaded image.
 */
// export const uploadFile = async (
//     file: File,
//     partnerName: string,
//     partnerCompanyUUID: string
// ): Promise<string | undefined> => {
//     try {
//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append('partner_name', partnerName);
//         formData.append('partner_company_uuid', partnerCompanyUUID);

//         const response = await fetch('https://capture-lendica.vercel.app/api/upload', {
//             method: 'POST',
//             body: formData,
//         });

//         const data = await response.json();
//         if (!data.success) throw new Error('Screenshot upload failed');

//         return data.file_url;
//     } catch (e) {
//         // ignore
//         return '';
//     }
// };

// export const getIpAddress = async () => {
//     const res = await fetch('https://api.ipify.org/?format=json');
//     const { ip } = await res.json();
//     return ip;
// };
